<template>
  <div @click.capture="getVariant" @variant-selected="onVariantSelected">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ProductVariantHandler',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  methods: {
    getVariant(e) {
      // We do not want to change modified clicks.
      if (e.ctrlKey || e.keyCode === 17) {
        return
      }

      let target = e.target
      while (target !== this.$el && target.parentElement !== null && !target.hasAttribute('data-slug')) {
        target = target.parentElement
      }

      if (target?.hasAttribute('data-slug') ?? false) {
        e.preventDefault()
        const dataSlug = target.getAttribute('data-slug')
        this.$emit('isVariantChange', dataSlug)
        this.$root.$emit('getVariant', dataSlug)
      }
    },
    onVariantSelected(variant) {
      const variantUrlSlug = variant.urlSlug[this.$i18n.locale]
      this.$emit('variant-selected', variantUrlSlug)
      this.$root.$emit('getVariant', variantUrlSlug)
    }
  },
  mounted() {},
  watch: {}
}
</script>

<style lang="scss"></style>
