<template>
  <div class="product-variant-loader">
    <b-overlay class="variant-loader" :opacity="0.9" :show="isLoading" no-wrap no-center spinner-variant="primary" />
    <v-runtime-template v-if="variant" :template="variant" />
  </div>
</template>

<script>
import { getUrlSlugs } from '@/assets/js/helper/url'

import VRuntimeTemplate from 'v-runtime-template'

const URL_SLUGS = getUrlSlugs()

export default {
  name: 'ProductWrapper',
  components: {
    VRuntimeTemplate
  },
  data() {
    return {
      variant: null,
      isLoading: false
    }
  },
  methods: {
    getPopstateVariant(event) {
      this.getVariant(URL_SLUGS[URL_SLUGS.length - 1], true)
    },
    getVariant(slug = '', replaceState = false) {
      const historyUrl = `${window.location.origin}${this.$store.getters['gui/urls:getType']('product', slug)}`
      const variantUrl = `${window.location.origin}${this.$store.getters['gui/urls:getType']('productvariant', slug)}`

      this.isLoading = true

      this.$http({
        method: 'get',
        url: variantUrl
      })
        .then((response) => {
          const contentMatch = response.data.match(/(?:<article class="product".*?>)([\s\S]*)(?:<\/article>)/)
          if (contentMatch === null || contentMatch.length < 1) {
            this.variant = null
            this.$bvToast.toast(this.$t('product.variant.notloaded.message'), {
              variant: 'danger',
              title: this.$t('product.variant.notloaded.title'),
              noCloseButton: true,
              isStatus: true,
              appendToast: true,
              autoHideDelay: 4000
            })
            return
          }
          const contentDom = new DOMParser().parseFromString(contentMatch[1] ?? '', 'text/html').body.innerHTML

          // Handle old original product content
          const nextElement = this.$el.nextElementSibling
          if (nextElement !== null) {
            nextElement.remove()
          }

          this.variant = `<article class="product">${contentDom}</article>`
          window.history[replaceState ? 'replaceState' : 'pushState'](null, '', historyUrl)
        })
        .catch((error) => {
          console.warn(error)
          this.variant = null
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  created() {
    this.$root.$on('getVariant', this.getVariant)
    window.addEventListener('popstate', this.getPopstateVariant)
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.getPopstateVariant)
  }
}
</script>

<style lang="scss">
// desktop
$product-breakpoint: $desktop-breakpoint !default;

.product-wrapper {
  position: relative;

  .variant-loader {
    > div + div {
      margin: 0 auto;
      padding-top: 75vh;
      width: 0;

      @include media-breakpoint-up($product-breakpoint) {
        padding-top: 30vh;
      }
    }
  }
}
</style>
