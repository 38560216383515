<template>
  <div class="product-wishlistbutton">
    <b-button
      class="wishlistbutton"
      :variant="link ? 'link' : 'outline-control'"
      :size="link ? 'md' : 'sm'"
      @click="processWishlistClick"
    >
      <lazy-icon :icon="wishlistIcon"/> <slot/>
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'ProductWishlistButton',
  props: {
    productId: {
      type: String,
      required: true
    },
    link: {
      type: Boolean,
      default: false
    },
    gtmList: {
      type: String,
      default: 'Category'
    }
  },
  computed: {
    itemIdsInWishlist () {
      return this.$store.getters['wishlist/getItemIds'] ?? []
    },
    isOnWishlist () {
      return this.itemIdsInWishlist.includes(this.productId)
    },
    wishlistIcon () {
      // TODO: Set correct Filled-SVG when available
      return this.isOnWishlist ? 'b-suit-heart-fill' : 'heart'
    }
  },
  methods: {
    processWishlistClick () {
      if (!this.isOnWishlist) {
        this.$store.dispatch('wishlist/addItem', { productId: this.productId, gtmList: this.gtmList })
      } else {
        this.$store.dispatch('wishlist/get')
          .then(wishlist => {
            const item = wishlist.items.find(x => x.product.id === this.productId) || {}
            if (item) {
              this.$store.dispatch('wishlist/deleteItem', { item: { id: item.basketItemId }, gtmList: this.gtmList })
            }
          })
      }
    }
  },
  watch: {
    itemIdsInWishlist () {
    }
  }
}
</script>

<style lang="scss">
$productwishlistbutton-link-color: $gray-400 !default;

.product-wishlistbutton {
  .wishlistbutton {
    display: block;
    width: 100%;

    &.btn-link {
      padding: 0;
      color: $productwishlistbutton-link-color;
    }
  }
}
</style>
