<template>
  <item-characteristics :item="item"/>
</template>

<script>
import ItemCharacteristics from '@/components/private/ItemCharacteristics'

export default {
  name: 'ProductCharacteristics',
  components: {
    ItemCharacteristics
  },
  props: {
    characteristics: {
      type: Array,
      required: true
    }
  },
  computed: {
    item () {
      return {
        configuration: {},
        product: {
          characteristics: this.characteristics
        }
      }
    }
  }
}
</script>

<style lang="scss">
$productcharacteristics-item-gap-y: 0 !default;
$productcharacteristics-item-gap-x: $spacer * 1.2 !default;

.product-characteristics {
  display: flex;
  flex-wrap: wrap;
  margin: ($productcharacteristics-item-gap-y * -0.5) ($productcharacteristics-item-gap-x * -0.5);

  > span {
    display: block;
    padding: ($productcharacteristics-item-gap-y * 0.5) ($productcharacteristics-item-gap-x * 0.5);
  }
}
</style>
