<template>
  <div class="product-shoppingcartbutton" :class="{'small' : small}">
    <b-button class="shoppingcartbutton" variant="custom" @click.stop="addToCart">
      <div class="shoppingcartbutton-quantity" @click.stop>
        <b-dropdown
          v-if="!quantity.customMode"
          :text="dropdownText"
          size="sm"
          dropup
          no-flip
        >
          <b-dropdown-item
            v-for="item in dropdownItems"
            :key="item"
            @click="setQuantity(item)"
          >{{ item }}</b-dropdown-item>
        </b-dropdown>

        <b-input
          v-if="quantity.customMode"
          ref="quantityControl"
          v-model="quantity.value"
          @blur="toggleQuantityCustomMode(false)"
        />
      </div>

      <div class="shoppingcartbutton-text"><slot/></div>

      <div class="shoppingcartbutton-quantity invisible" v-if="!small" aria-hidden="true">
        <b-dropdown v-if="!quantity.customMode" :text="dropdownText" disabled tabindex="-1"></b-dropdown>
        <b-input v-if="quantity.customMode" v-model="quantity.value" disabled tabindex="-1"/>
      </div>
    </b-button>
  </div>
</template>

<script>
import { COMPONENT_PRODUCTBUYBUTTON_DROPDOWN_MAX_ITEMS, COMPONENT_PRODUCTBUYBUTTON_QUANTITY_DEFAULTS } from '@/constants'

import { closest } from '@/assets/js/helper/array'

export default {
  name: 'ProductShoppingcartButton',
  props: {
    productId: {
      type: String,
      required: true
    },
    quantityinfo: {
      type: Object,
      default: () => ({})
    },
    showCustomQuantity: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    },
    gtmList: {
      type: String,
      default: 'Warenkorb'
    },
    reloadFullBasketOnAdd: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      quantity: {
        customMode: false,
        value: null,
        tempValue: null
      }
    }
  },
  computed: {
    quantityOptions () {
      // must be >= 1, fallback = 1
      const max = Math.trunc(Math.max(1, Number(this.quantityinfo.max || COMPONENT_PRODUCTBUYBUTTON_QUANTITY_DEFAULTS.max)))
      // must be >= 1 and <= max, fallback = 1 or max
      const min = Math.trunc(Math.max(1, Math.min(max, Number(this.quantityinfo.min || COMPONENT_PRODUCTBUYBUTTON_QUANTITY_DEFAULTS.min))))
      // must be >= 1, fallback = 1
      const step = Math.trunc(Math.max(1, Number(this.quantityinfo.step || COMPONENT_PRODUCTBUYBUTTON_QUANTITY_DEFAULTS.step)))

      return {
        min,
        max,
        range: max - min,
        step
      }
    },
    quantitySteps () {
      // add 1 for initial step (e.g. quantityOptions.min)
      const stepCount = Math.trunc(this.quantityOptions.range / this.quantityOptions.step) + 1

      return Array
        .from(Array(stepCount).keys())
        .map(step => step === 0 ? this.quantityOptions.min : this.quantityOptions.min + step * this.quantityOptions.step)
    },
    quantityInit () {
      // must by one >= 1 and <= quantitySteps.length, fallback = 0 or quantitySteps.length - 1
      const initialStep = Number(Math.min(this.quantitySteps.length, Math.max(1, this.quantityinfo.initialStep || COMPONENT_PRODUCTBUYBUTTON_QUANTITY_DEFAULTS.initialStep))) - 1
      return this.quantitySteps[initialStep]
    },
    dropdownText () {
      return (this.quantity.value || '').toString()
    },
    dropdownItems () {
      let result = this.quantitySteps
        .slice(0, COMPONENT_PRODUCTBUYBUTTON_DROPDOWN_MAX_ITEMS)
      if (this.showCustomQuantity) {
        result = result.concat(this.quantitySteps.length > COMPONENT_PRODUCTBUYBUTTON_DROPDOWN_MAX_ITEMS ? this.$t(`${this.tPath}.customquantitylabel`) : [])
      }
      return result
    }
  },
  methods: {
    setQuantity (value = this.quantityOptions.min) {
      if (typeof value === 'number') {
        this.quantity.value = value
      } else {
        this.toggleQuantityCustomMode(true)
      }
    },
    toggleQuantityCustomMode (state = false) {
      if (state) {
        this.quantity.tempValue = this.quantity.value
        this.quantity.value = null
        this.quantity.customMode = true

        this.$nextTick(() => {
          this.$refs.quantityControl.focus()
        })
      } else {
        const quantityValue = Number(this.quantity.value)
        const isNonNumberic = !/^-?([0-9.]){1,}$/.test(this.quantity.value)
        const isOutOfRange = quantityValue < this.quantityOptions.min && quantityValue > this.quantityOptions.max
        const isOutOfSteps = !this.quantitySteps.includes(quantityValue)

        if (isNonNumberic || isOutOfRange) {
          this.quantity.value = this.quantity.tempValue
          this.quantity.tempValue = null
          this.quantity.customMode = false
        } else if (isOutOfSteps) {
          this.quantity.value = closest(this.quantitySteps, quantityValue)
        }
      }
    },
    async addToCart () {
      this.$store.dispatch('shoppingcart/addItem', { productId: this.productId, quantity: this.quantity.value, gtmList: this.gtmList, reloadFullBasket: this.reloadFullBasketOnAdd })
    }
  },
  created () {
    this.quantity.value = this.quantityInit
  }
}
</script>

<style lang="scss">
$productshoppingcartbutton-padding-y: $btn-padding-y * 0.4 !default;
$productshoppingcartbutton-padding-x: $productshoppingcartbutton-padding-y !default;
$productshoppingcartbutton-bg: $primary !default;
$productshoppingcartbutton-border: transparent !default;
$productshoppingcartbutton-font-weight: $font-weight-bold !default;
$productshoppingcartbutton-line-height: 1.1 !default;
$productshoppingcartbutton-hover-bg: darken($productshoppingcartbutton-bg, 5%) !default;
$productshoppingcartbutton-hover-border: darken($productshoppingcartbutton-border, 5%) !default;

$productshoppingcartbutton-quantity-width: $spacer * 4 !default;
$productshoppingcartbutton-quantity-height: $input-height-sm !default;

$productshoppingcartbutton-quantity-toggle-padding-y: 0 !default;
$productshoppingcartbutton-quantity-toggle-padding-x: $btn-padding-x * 0.5 !default;
$productshoppingcartbutton-quantity-toggle-bg: darken($productshoppingcartbutton-bg, 2.5%) !default;
$productshoppingcartbutton-quantity-toggle-border: darken($productshoppingcartbutton-border, 2.5%) !default;
$productshoppingcartbutton-quantity-toggle-hover-bg: darken($productshoppingcartbutton-quantity-toggle-bg, 6%) !default;
$productshoppingcartbutton-quantity-toggle-hover-border: darken($productshoppingcartbutton-quantity-toggle-border, 6%) !default;

$productshoppingcartbutton-quantity-menu-bg: $productshoppingcartbutton-bg !default;
$productshoppingcartbutton-quantity-menu-border: $productshoppingcartbutton-border !default;
$productshoppingcartbutton-quantity-menu-caret-size: $spacer * 0.6 !default;
$productshoppingcartbutton-quantity-menu-item-padding-y: $dropdown-item-padding-y !default;
$productshoppingcartbutton-quantity-menu-item-padding-x: $dropdown-item-padding-x * 0.6 !default;
$productshoppingcartbutton-quantity-menu-item-hover-bg: $productshoppingcartbutton-hover-bg !default;

$productshoppingcartbutton-quantity-control-padding-y: $productshoppingcartbutton-quantity-toggle-padding-y !default;
$productshoppingcartbutton-quantity-control-padding-x: $productshoppingcartbutton-quantity-toggle-padding-x !default;
$productshoppingcartbutton-quantity-control-bg: $productshoppingcartbutton-quantity-toggle-bg !default;
$productshoppingcartbutton-quantity-control-border: $productshoppingcartbutton-quantity-toggle-bg !default;
$productshoppingcartbutton-quantity-control-hover-bg: $productshoppingcartbutton-quantity-toggle-hover-bg !default;
$productshoppingcartbutton-quantity-control-hover-border: $productshoppingcartbutton-quantity-toggle-hover-border !default;
$productshoppingcartbutton-quantity-control-focus-bg: $productshoppingcartbutton-quantity-toggle-hover-bg !default;
$productshoppingcartbutton-quantity-control-focus-border: $white !default;

$productshoppingcartbutton-text-padding-y: 0 !default;
$productshoppingcartbutton-text-padding-x: $spacer !default;

.product-shoppingcartbutton {
  .shoppingcartbutton {
    @include button-variant($productshoppingcartbutton-bg, $productshoppingcartbutton-border, $productshoppingcartbutton-hover-bg, $productshoppingcartbutton-hover-border);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $productshoppingcartbutton-padding-y $productshoppingcartbutton-padding-x;
    width: 100%;
    font-weight: $productshoppingcartbutton-font-weight;
    line-height: $productshoppingcartbutton-line-height;

    .shoppingcartbutton-quantity {
      width: $productshoppingcartbutton-quantity-width;
      height: $productshoppingcartbutton-quantity-height;

      .dropdown {
        width: 100%;
        height: 100%;

        .dropdown-toggle {
          @include button-variant($productshoppingcartbutton-quantity-toggle-bg, $productshoppingcartbutton-quantity-toggle-border, $productshoppingcartbutton-quantity-toggle-hover-bg, $productshoppingcartbutton-quantity-toggle-hover-border);
          padding-top: $productshoppingcartbutton-quantity-toggle-padding-y;
          padding-bottom: $productshoppingcartbutton-quantity-toggle-padding-y;
          padding-left: $productshoppingcartbutton-quantity-toggle-padding-x;
          height: 100%;
          background-image: escape-svg(str-replace($dropdown-toggle-icon, "fill='#{$dropdown-toggle-color}'", "fill='#{color-yiq($productshoppingcartbutton-quantity-toggle-bg)}'"));
          box-shadow: none!important;
        }

        .dropdown-menu {
          margin-bottom: 0;
          margin-left: 50%;
          min-width: 0;
          background-color: $productshoppingcartbutton-quantity-menu-bg;
          border-color: $productshoppingcartbutton-quantity-menu-border;
          color: color-yiq($productshoppingcartbutton-quantity-menu-bg);
          transform: translate3d(-50%, calc(-100% - ($productshoppingcartbutton-padding-y + $productshoppingcartbutton-quantity-menu-caret-size + 2px)), 0)!important;

          &:after {
            display: block;
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            width: 0;
            height: 0;
            border-width: $productshoppingcartbutton-quantity-menu-caret-size $productshoppingcartbutton-quantity-menu-caret-size 0;
            border-style: solid solid none;
            border-color: $productshoppingcartbutton-quantity-menu-bg transparent transparent;
            transform: translate3d(-50%, 0, 0);
          }

          .dropdown-item {
            padding: $productshoppingcartbutton-quantity-menu-item-padding-y $productshoppingcartbutton-quantity-menu-item-padding-x;
            color: inherit;

            &:hover {
              background-color: $productshoppingcartbutton-quantity-menu-item-hover-bg;
            }
          }
        }
      }

      .form-control {
        padding: $productshoppingcartbutton-quantity-control-padding-y $productshoppingcartbutton-quantity-control-padding-x;
        height: 100%;
        background-color: $productshoppingcartbutton-quantity-control-bg;
        border-color: $productshoppingcartbutton-quantity-control-border;
        border-radius: $dropdown-border-radius;
        color: color-yiq($productshoppingcartbutton-quantity-control-bg);
        text-align: center;
        transition: $transition-base;

        &:focus {
          background-color: $productshoppingcartbutton-quantity-control-focus-bg!important;
          border-color: $productshoppingcartbutton-quantity-control-focus-border!important;
        }
      }
    }

    .shoppingcartbutton-text {
      padding: $productshoppingcartbutton-text-padding-y $productshoppingcartbutton-text-padding-x;
    }

    &:hover {
      .shoppingcartbutton-quantity {
        .dropdown {
          .dropdown-toggle {
            color: color-yiq($productshoppingcartbutton-quantity-toggle-hover-bg);
            @include gradient-bg($productshoppingcartbutton-quantity-toggle-hover-bg);
            border-color: $productshoppingcartbutton-quantity-toggle-hover-border;
          }
        }

        .form-control {
          background-color: $productshoppingcartbutton-quantity-control-hover-bg;
          border-color: $productshoppingcartbutton-quantity-control-hover-bg;
        }
      }
    }
  }

  &.small{
    .shoppingcartbutton{
      .shoppingcartbutton-quantity{
        height: auto;
        width: auto;
        .dropdown {
          .dropdown-toggle{
            padding: 0 1.2rem 0 0.8rem;
            background-position: center right;
          }
        }
      }
      .shoppingcartbutton-text{
        padding: 0 0.5rem;
      }
    }
  }
}
</style>
