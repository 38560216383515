var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'productdata',
    {
      'is-accordion': !_vm.$root.isDesktop,
      'is-expandable': _vm.$root.isDesktop ? _vm.expandable : false,
      'is-expanded': _vm.isExpanded
    }
  ],attrs:{"aria-expanded":_vm.expandable ? _vm.isExpanded ? 'true' : 'false' : null}},[_c('b-row',[_c('b-col',_vm._b({},"b-col",_vm._d({},[_vm.$root.md.key,3])),[(!_vm.$root.isDesktop)?_c('c-accordion-toggle',{attrs:{"target":`${this._uid}-accordion`,"shapeless":""}},[_c('div',{staticClass:"productdata-title"},[_vm._t("title")],2)]):_c('div',{staticClass:"productdata-title"},[_vm._t("title")],2)],1),_c('b-col',[(!_vm.$root.isDesktop)?_c('c-accordion-content',{attrs:{"id":`${this._uid}-accordion`,"group":`${this._uid}-accordion`,"visible":_vm.isExpanded,"shapeless":""},on:{"show":$event => _vm.toggleExpanded(true),"hide":$event => _vm.toggleExpanded(false)}},[_c('div',[_c('div',{staticClass:"productdata-content"},[_vm._t("default")],2)])]):[(_vm.expandable)?[_c('transition-expand',{attrs:{"expanded":_vm.isExpanded,"minHeight":_vm.minHeight},scopedSlots:_vm._u([{key:"default",fn:function({ isOverflowing }){return [_c('div',{key:_vm.isExpanded,class:['productdata-content', { 'is-underflowing': !isOverflowing }]},[_vm._t("default")],2)]}}],null,true)}),_c('a',{staticClass:"productdata-expander",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return ($event => _vm.toggleExpanded()).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t(`${_vm.tPath}.expander.${_vm.isExpanded ? 'collapse' : 'expand'}`))+" "),_c('lazy-icon',{attrs:{"icon":"caret-down","scale":"0.75"}})],1)]:_c('div',{staticClass:"productdata-content"},[_vm._t("default")],2)]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }