<template>
  <div class="product-dropdown">
    <div class="dropdown-title" v-if="$slots.title">
      <slot name="title"/>
    </div>

    <b-dropdown :text="dropdownText" variant="outline-control" :size="dropdownSize" no-flip>
      <b-dropdown-item
        v-for="(item, iIndex) in items"
        :key="`${item.href}_${iIndex}`"
        :href="item.href"
        :data-slug="item.href"
        :active="item.active"
      >{{ item.text }}

        <template v-if="item.description">
          <lazy-icon :id="`popover-toggle-${item.href}_${iIndex}`" class="dropdown-icon" icon="b-info-circle" variant="info" scale="0.75" @click.stop/>
          <b-popover
            :target="`popover-toggle-${item.href}_${iIndex}`"
            placement="top"
            triggers="hover"
            :content="item.description"
          />
        </template>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { INPUT_BUTTON_SIZE_MAP } from '@/constants'

export default {
  name: 'ProductDropdown',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      dropdownText: (this.$props.items.find(i => i.active) || {}).text
    }
  },
  computed: {
    dropdownSize () {
      return INPUT_BUTTON_SIZE_MAP[this.$root.mobilefirstBreakpoint.key]
    }
  }
}
</script>

<style lang="scss">
$productcolors-title-gap: $spacer * 0.5 !default;
$productcolors-title-font-size: $font-size-sm !default;
$productcolors-title-color: $gray-700 !default;

$productcolors-dropdown-icon-gap: $spacer !default;

.product-dropdown {
  .dropdown-title {
    margin-bottom: $productcolors-title-gap;
    font-size: $productcolors-title-font-size;
    color: $productcolors-title-color;
  }

  .dropdown {
    .dropdown-icon {
      margin-left: $productcolors-dropdown-icon-gap;
    }
  }
}
</style>
