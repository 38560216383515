<template functional>
  <div :id="data.id" :class="['product-inshowroom', data.class, data.staticClass]" :style="data.style" v-bind="data.attrs">
    <lazy-icon icon="house" variant="dark"/><slot/>
  </div>
</template>

<script>
export default {
  name: 'ProductInShowroom'
}
</script>

<style lang="scss">
$productinshowroom-gap-y: $spacer * 0.4 !default;
$productinshowroom-font-size: $font-size-sm !default;
$productinshowroom-color: $gray-600 !default;
$productinshowroom-line-height: 1.2 !default;

$productinshowroom-icon-gap: $spacer * 0.6 !default;
$productinshowroom-icon-size: $font-size-base !default;

.product-inshowroom {
  display: flex;
  align-items: center;
  margin-top: $productinshowroom-gap-y;
  margin-bottom: $productinshowroom-gap-y;
  font-size: $productinshowroom-font-size;
  color: $productinshowroom-color;
  line-height: $productinshowroom-line-height;

  .bi {
    margin-right: $productinshowroom-icon-gap;
    font-size: $productinshowroom-icon-size;
  }
}
</style>
