import merge from 'lodash.merge'

export function closest (array = [], number = null) {
  return array
    .map(n => ({ v: n, d: Math.abs(number - n) }))
    .sort((a, b) => a.d < b.d ? -1 : a.d > b.d ? 1 : 0)[0].v
}

export function copy (data) {
  return merge([], data)
}

export default {
  closest,
  copy
}
