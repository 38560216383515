<template>
  <div class="product-assemblytype">
    <b-form-radio-group :name="`${tPath}.Radiogroup`" v-model="activeType" :options="types" />
  </div>
</template>

<script>
import { PRODUCT_ASSEMBLYTYPE_DEFAULT } from '@/constants'

export default {
  name: 'ProductAssemblytype',
  props: {
    productId: {
      type: String,
      required: true
    },
    types: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activeType: (this.types.find((t) => t.active) || {}).value || PRODUCT_ASSEMBLYTYPE_DEFAULT
    }
  },
  watch: {
    activeType: {
      immediate: true,
      handler() {
        this.$store.commit('shoppingcart/setLocalItemConfiguration', { productId: this.productId, assemblyType: this.activeType })
      }
    }
  }
}
</script>

<style lang="scss">
.product-assemblytype {
  .custom-radio {
    input:not(:checked) + .custom-control-label {
      color: $gray-700;
    }

    @include media-breakpoint-up($desktop-breakpoint) {
      &:hover {
        input:not(:checked) + .custom-control-label {
          color: $gray-700;

          &::after {
            background-image: escape-svg(str-replace($custom-radio-indicator-icon-checked, "fill='#000'", "fill='rgba(0, 0, 0, 0.54)'"));
          }
        }
      }
    }
  }
}
</style>
