<template>
  <b-modal
    :id="id"
    v-model="show"
    size="lg"
    centered
    no-fade
  >
    <template #modal-header="slotProps">
      <slot name="header"/>
      <b-button class="close p-3" variant="default" @click="slotProps.close"><lazy-icon icon="remove"/></b-button>
    </template>

    <template #default>
      <slot name="default"/>
    </template>

    <template #modal-footer>
      <slot name="footer" v-bind="{ close }"/>
    </template>
  </b-modal>
</template>

<script>
const OPEN_MODAL_CART_ACTION_TYPS = ['ItemAdded', 'ItemChanged']

export default {
  name: 'RelatedProductsModal',
  props: {
    productId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      id: `RelatedProductsModal_${this._uid}`,
      show: false
    }
  },
  computed: {
    lastShoppingCartAction () {
      return this.$store.getters['shoppingcart/getLastAction']
    }
  },
  methods: {
    open () {
      this.show = true
    },
    close () {
      this.show = false
    }
  },
  watch: {
    lastShoppingCartAction () {
      if (OPEN_MODAL_CART_ACTION_TYPS.includes(this.lastShoppingCartAction.type) && this.lastShoppingCartAction.product.id === this.productId) this.open()
    }
  }
}
</script>

<style lang="scss">
.modal {
  .modal-header {
    .close {
      margin-left: 0;
      font-size: inherit;
    }
  }
}
</style>
