<template>
  <div class="shareing-is-caring">
    <b-button :id="`PopoverTarget_${_uid}`" variant="link" class="share-button"> <lazy-icon icon="share" /> {{ $t(`${tPath}.share.button`) }} </b-button>
    <b-popover :target="`PopoverTarget_${_uid}`" triggers="hover" placement="rightbottom">
      <slot />
    </b-popover>
  </div>
</template>
<script>
export default {
  name: 'ProductShareButtons'
}
</script>
