<template>
  <div :class="['product-colors', { 'is-compact': compact, 'is-extended': extended }]">
    <div class="colors-title" v-if="$slots.title && !compact">
      <slot name="title" />
    </div>

    <ul class="colors-list">
      <li v-for="(color, cIndex) in colorItems" :key="`${color.href}_${cIndex}`" :class="{ active: color.active, 'large-item': largePreview }">
        <div class="list-item" :data-slug="color.href">
          <a
            :class="['color', { 'inverted-foreground-color': color.hsv && color.hsv.v < 0.5 }]"
            :href="$store.getters['gui/urls:getType']('product', color.href)"
            :data-slug="color.href"
            :title="color.text"
            :style="{ 'background-color': color.color, 'background-image': color.colorUrl ? `url(${color.colorUrl})` : '' }"
            @mouseover="$emit('color-mouseover', color.mainImage)"
            @mouseleave="$emit('color-mouseleave')"
          >
            <lazy-icon v-if="extended && color.active" class="check-icon" icon="b-check-circle-fill" />
          </a>
          <span :class="['color-name', { 'sr-only': !extended }]">{{ color.text }}</span>
        </div>
      </li>

      <li v-if="isOverlength" class="overlength">
        <div class="list-item">
          <a :href="$store.getters['gui/urls:getType']('product', activeItem.href)" class="color">
            <lazy-icon :id="`Popover_${_uid}`" icon="dots-circle" />
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import tinyColor from 'tinycolor2'

export default {
  name: 'ProductColors',
  props: {
    colors: {
      type: Array,
      required: true
    },
    maxItems: {
      type: [Number, String],
      default: null
    },
    compact: {
      type: Boolean,
      default: false
    },
    extended: {
      type: Boolean,
      default: false
    },
    largePreview: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isOverlength() {
      const max = Number(this.maxItems)
      return this.maxItems !== null && this.sortedColors.length > max - 1
    },
    sortedColors() {
      const uniqueColors = []
      this.colors.forEach((color) => {
        var duplicates = this.colors.filter((x) => x.text === color.text)
        if (duplicates.length > 1) {
          const activeItem = duplicates.find((x) => x.active)
          if (activeItem && !uniqueColors.find((x) => x.text === activeItem.text)) {
            uniqueColors.push(activeItem)
          } else if (!uniqueColors.find((x) => x.text === color.text)) {
            uniqueColors.push(color)
          }
        } else {
          uniqueColors.push(color)
        }
      })

      if (this.extended) {
        const hueClusters = [
          { min: 0, max: 60 },
          { min: 60, max: 120 },
          { min: 120, max: 180 },
          { min: 180, max: 250 },
          { min: 250, max: 330 },
          { min: 330, max: 360 }
        ]

        return (
          []
            .concat(uniqueColors)
            .map((c) => Object.assign({ hsv: tinyColor(c.color).toHsv() }, c))
            // sort by value (brightness)
            .sort((a, b) => (a.hsv.v > b.hsv.v ? -1 : a.hsv.v < b.hsv.v ? 1 : 0))
            // // // sort by cluster
            .sort(
              (a, b) =>
                hueClusters.findIndex((claster) => claster.min <= a.hsv.h && claster.max >= a.hsv.h) -
                hueClusters.findIndex((claster) => claster.min <= b.hsv.h && claster.max >= b.hsv.h)
            )
            // // // sort by cluster saturation
            .sort((a, b) =>
              hueClusters.findIndex((claster) => claster.min <= a.hsv.h && claster.max >= a.hsv.h) ===
              hueClusters.findIndex((claster) => claster.min <= b.hsv.h && claster.max >= b.hsv.h)
                ? b.hsv.v - a.hsv.v
                : 0
            )
            // // // sort by saturation and value (custom)
            .sort((a, b) =>
              (a.hsv.s <= 0.1 && a.hsv.v <= 0.9) || a.hsv.v <= 0.2 ? 1 : (b.hsv.s <= 0.1 && b.hsv.v <= 0.9) || b.hsv.v <= 0.2 ? -1 : 0
            )
        )
      }
      return uniqueColors
    },
    colorItems() {
      if (this.isOverlength) {
        const max = Number(this.maxItems) - 1

        const activeIndex = this.sortedColors.findIndex((c) => c.active)
        const offset = Math.round(max / 2)

        let startIndex = activeIndex - offset
        let endIndex = activeIndex + (max - offset - 1)

        if (startIndex < 0) {
          endIndex = endIndex + Math.abs(startIndex)
          startIndex = 0
        }

        if (endIndex > this.sortedColors.length - 1) {
          const diff = endIndex - (this.sortedColors.length - 1)
          startIndex = startIndex - diff
          endIndex = this.sortedColors.length - 1
        }

        return this.sortedColors.slice(startIndex, endIndex + 1)
      }

      return this.sortedColors
    },
    activeItem() {
      return this.sortedColors.find((c) => c.active) ?? this.sortedColors[0] ?? {}
    }
  }
}
</script>

<style lang="scss">
// base
$productcolors-title-gap: $spacer * 0.5 !default;
$productcolors-title-font-size: $font-size-sm !default;
$productcolors-title-color: $gray-700 !default;

$productcolors-list-item-gap: $spacer * 0.4375 !default;
$productcolors-list-item-size: 2.5em !default;
$productcolors-list-item-border: $border-width solid rgba($gray-700, 0.15) !default;
$productcolors-list-item-border-radius: 50% !default;
$productcolors-list-item-icon-color: $gray-700 !default;

$productcolors-list-item-active-border-offset: $productcolors-list-item-size * -0.15 !default;
$productcolors-list-item-active-border-width: nth(map-get($borders, 'bold'), 1) !default;
$productcolors-list-item-active-border-color: $black !default;

$productcolors-list-item-hover-border-color: rgba($productcolors-list-item-active-border-color, 0.1) !default;

// Desktop
$product-breakpoint: $desktop-breakpoint !default;

// compact
$productcolors-compact-list-item-gap: $spacer * 0.3125 !default;
$productcolors-compact-list-item-size: 1.625em !default;
$productcolors-compact-list-item-border: $productcolors-list-item-border !default;
$productcolors-compact-list-item-border-radius: $productcolors-list-item-border-radius !default;
$productcolors-compact-list-item-icon-color: $productcolors-list-item-icon-color !default;

$productcolors-compact-list-item-active-border-offset: $productcolors-list-item-active-border-width * -1.5 !default;
$productcolors-compact-list-item-active-border-width: $productcolors-list-item-active-border-width !default;
$productcolors-compact-list-item-active-border-color: $gray-700 !default;

$productcolors-compact-list-item-hover-border-color: rgba($productcolors-compact-list-item-active-border-color, 0.5) !default;

// extended
$productcolors-extended-list-item-gap: 2.5% !default;
$productcolors-extended-list-item-size: 17.5% !default;
$productcolors-extended-list-item-border: $productcolors-list-item-border !default;
$productcolors-extended-list-item-border-radius: $border-radius !default;
$productcolors-extended-list-item-icon-color: $productcolors-list-item-icon-color !default;
$productcolors-extended-list-item-text-font-size: 12px !default;

$productcolors-extended-list-large-item-size: 30.833% !default;

$productcolors-extended-list-item-active-border-offset: $productcolors-list-item-active-border-width * -1 !default;
$productcolors-extended-list-item-active-border-width: $productcolors-list-item-active-border-width !default;
$productcolors-extended-list-item-active-border-color: $black !default;

$productcolors-extended-list-item-hover-border-color: rgba($productcolors-extended-list-item-active-border-color, 0.5) !default;

.product-colors {
  width: 100%;

  .colors-title {
    margin-bottom: $productcolors-title-gap;
    font-size: $productcolors-title-font-size;
    color: $productcolors-title-color;
  }

  .colors-list {
    @include list-unstyled();
    display: flex;
    flex-wrap: wrap;
    margin: $productcolors-list-item-gap * -0.5;

    > li {
      padding: $productcolors-list-item-gap * 0.5;
      width: calc($productcolors-list-item-size - $productcolors-list-item-gap);

      .list-item {
        cursor: pointer;

        .color {
          display: block;
          position: relative;
          margin: auto;
          padding-top: calc(100% - (nth($productcolors-list-item-border, 1) * 2));
          border: $productcolors-list-item-border;
          border-radius: $productcolors-list-item-border-radius;
          color: inherit;
          text-decoration: none;
          background-size: cover;
          background-position: center;

          &.inverted-foreground-color {
            color: $white;
          }

          &:before {
            display: block;
            content: '';
            position: absolute;
            inset: $productcolors-list-item-active-border-offset;
            border: $productcolors-list-item-active-border-width solid transparent;
            border-radius: inherit;
          }

          .bi {
            display: block;
            margin: calc(-1px + (nth($productcolors-list-item-border, 1) * -1));
            width: calc(2px + 100% + (nth($productcolors-list-item-border, 1) * 2));
            height: calc(2px + 100% + (nth($productcolors-list-item-border, 1) * 2));
            color: $productcolors-list-item-icon-color;
          }
        }

        &:hover {
          .color {
            &:before {
              border-color: $productcolors-list-item-hover-border-color;
            }
          }
        }
      }

      &.active {
        .list-item {
          .color {
            box-shadow: none;

            &:before {
              border-color: $productcolors-list-item-active-border-color;
            }
          }
        }
      }

      &.overlength {
        .list-item {
          .color {
            padding-top: 0;
            border-color: transparent;
          }
        }
      }
    }
  }

  &.is-compact {
    .colors-list {
      margin: $productcolors-compact-list-item-gap * -0.5;

      > li {
        padding: $productcolors-compact-list-item-gap * 0.5;
        width: calc($productcolors-compact-list-item-size - $productcolors-compact-list-item-gap);

        .list-item {
          .color {
            padding-top: calc(100% - (nth($productcolors-compact-list-item-border, 1) * 2));
            border: $productcolors-compact-list-item-border;
            border-radius: $productcolors-compact-list-item-border-radius;

            &:before {
              inset: $productcolors-compact-list-item-active-border-offset;
              border-width: $productcolors-compact-list-item-active-border-width;
            }

            .bi {
              margin: calc(-1px + (nth($productcolors-compact-list-item-border, 1) * -1));
              width: calc(2px + 100% + (nth($productcolors-compact-list-item-border, 1) * 2));
              height: calc(2px + 100% + (nth($productcolors-compact-list-item-border, 1) * 2));
              color: $productcolors-compact-list-item-icon-color;
            }

            .check-icon {
              display: none;
            }
          }

          &:hover {
            .color {
              &:before {
                border-color: $productcolors-compact-list-item-hover-border-color;
              }
            }
          }
        }

        &.active {
          .list-item {
            .color {
              &:before {
                border-color: $productcolors-compact-list-item-active-border-color;
              }
            }
          }
        }

        &.overlength {
          .list-item {
            .color {
              padding-top: 0;
              border-color: transparent;
            }
          }
        }
      }
    }
  }

  &.is-extended {
    .colors-list {
      margin: $productcolors-extended-list-item-gap * -0.5;

      > li {
        padding: $productcolors-extended-list-item-gap * 0.5;
        width: calc($productcolors-extended-list-item-gap + $productcolors-extended-list-item-size);

        &.large-item {
          width: calc($productcolors-extended-list-item-gap + $productcolors-extended-list-large-item-size);
        }

        .list-item {
          .color {
            padding-top: calc(100% - (nth($productcolors-extended-list-item-border, 1) * 2));
            border: $productcolors-extended-list-item-border;
            border-radius: $productcolors-extended-list-item-border-radius;

            &:before {
              inset: $productcolors-extended-list-item-active-border-offset;
              border-width: $productcolors-extended-list-item-active-border-width;
              border-radius: $productcolors-extended-list-item-border-radius * 1.5;
            }

            .bi {
              margin: calc(-1px + (nth($productcolors-extended-list-item-border, 1) * -1));
              width: calc(2px + 100% + (nth($productcolors-extended-list-item-border, 1) * 2));
              height: calc(2px + 100% + (nth($productcolors-extended-list-item-border, 1) * 2));
              color: $productcolors-extended-list-item-icon-color;
            }

            .check-icon {
              color: inherit;
              position: absolute;
              bottom: 5px;
              right: 5px;
              width: auto;
              height: auto;
              font-size: 125%;
            }
          }

          .color-name {
            display: block;
            width: 100%;
            font-size: $productcolors-extended-list-item-text-font-size;
            text-align: center;
            line-height: 1.1;
          }

          &:hover {
            .color {
              &:before {
                border-color: $productcolors-extended-list-item-hover-border-color;
              }
            }
          }
        }

        &.active {
          .list-item {
            .color {
              &:before {
                border-color: $productcolors-extended-list-item-active-border-color;
              }
            }
          }
        }

        &.overlength {
          .list-item {
            .color {
              padding-top: 0;
              border-color: transparent;
            }
          }
        }
      }
    }
  }
}
</style>
